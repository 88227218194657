.wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    padding: 100px 0px;
}
.left-con{

}
.left{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 30vw;
    margin: 0px 20px;
}
.title{
    font-family: Merriweather;
    font-size: 25px;
    font-weight: 700;
    line-height: 22px;
    color: #FFCF24;
}
.sub-title{
    font-family: Merriweather;
    font-weight: 700;
    font-size: 42px;
    line-height: 53px;
    color: #616161;
}
.paragraph{
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 34px;
    color: #939090;
}
.vision-btn{
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    background-color: #FFCF24;
    padding: 20px 55px;
    border: none;
    outline: none;
}
.vision-btn:hover{
    background-color: #15ABAB;
}
.right{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50vw;
    margin: 0px 30px;
}
.img-1{
    width: 23vw;
    height: 32rem;
    margin: 0px 7px;
}
.img-2{
    width: 23vw;
    height: 32rem;
    margin: 0px 7px;

}

@media screen and (max-width: 1024px) {
    .wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        padding: 100px 0px;
    }
    /* .left-con{
    
    } */
    .left{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 30vw;
        margin: 0px 20px;
    }
    .title{
        font-family: Merriweather;
        font-size: 23px;
        font-weight: 700;
        line-height: 20px;
        color: #FFCF24;
    }
    .sub-title{
        font-family: Merriweather;
        font-weight: 700;
        font-size: 32px;
        line-height: 33px;
        color: #616161;
    }
    .paragraph{
        font-family: Plus Jakarta Sans;
        font-size: 14px;
        font-weight: 600;
        line-height: 30px;
        color: #939090;
    }
    .vision-btn{
        font-family: Inter;
        font-size: 13px;
        font-weight: 700;
        background-color: #FFCF24;
        padding: 15px 45px;
        border: none;
        outline: none;
    }
    .right{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50vw;
        margin: 0px 30px;
    }
    .img-1{
        width: 25vw;
        height: 30rem;
        margin: 0px 7px;
    }
    .img-2{
        width: 25vw;
        height: 30rem;
        margin: 0px 7px;
    
    }

}
@media screen and (max-width: 768px) {
    .wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        padding: 75px 0px;
    }
    /* .left-con{
    
    } */
    .left{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 30vw;
        margin: 0px 15px;
    }
    .title{
        font-family: Merriweather;
        font-size: 20px;
        font-weight: 700;
        line-height: 20px;
        color: #FFCF24;
    }
    .sub-title{
        font-family: Merriweather;
        font-weight: 700;
        font-size: 27px;
        line-height: 23px;
        color: #616161;
    }
    .paragraph{
        font-family: Plus Jakarta Sans;
        font-size: 13px;
        font-weight: 500;
        line-height: 27px;
        color: #939090;
    }
    .vision-btn{
        font-family: Inter;
        font-size: 12px;
        font-weight: 600;
        background-color: #FFCF24;
        padding: 13px 45px;
        border: none;
        outline: none;
    }
    .right{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50vw;
        margin: 0px 30px;
    }
    .img-1{
        width: 25vw;
        height: 25rem;
        margin: 0px 7px;
    }
    .img-2{
        width: 25vw;
        height: 25rem;
        margin: 0px 7px;
    
    }

}
@media screen and (max-width: 540px) {
    .wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
        padding: 75px 0px;
    }
    /* .left-con{
    
    } */
    .left{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 80vw;
        margin: 0px 15px;
        margin-bottom: 40px;
    }
    .title{
        font-family: Merriweather;
        font-size: 20px;
        font-weight: 700;
        line-height: 20px;
        color: #FFCF24;
    }
    .sub-title{
        font-family: Merriweather;
        font-weight: 700;
        font-size: 27px;
        line-height: 23px;
        color: #616161;
    }
    .paragraph{
        font-family: Plus Jakarta Sans;
        font-size: 13px;
        font-weight: 500;
        line-height: 27px;
        color: #939090;
    }
    .vision-btn{
        font-family: Inter;
        font-size: 12px;
        font-weight: 600;
        background-color: #FFCF24;
        padding: 13px 45px;
        border: none;
        outline: none;
    }
    .right{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80vw;
        margin: 0px 30px;
    }
    .img-1{
        width: 80vw;
        height: 20rem;
        margin: 20px 0px;
    }
    .img-2{
        width: 80vw;
        height: 20rem;
        margin: 20px 0px;
    
    }

}