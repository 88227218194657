.wrapper{
    background-image: url('../../Assets/vector12.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 635px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 100px 0px 100px;
}
.container{
    width: 90vw;
}
.top{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 30px 0px 0px 0px;
}
.left{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 40vw;
    margin-right: 100px;
    margin-top: -50px;
}
.left-1-con{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 15vw;
    margin-left: -35px;
    margin-bottom: -10px;
}
.left-1{
}
.left-2{
    font-family: Plus Jakarta Sans;
    font-size: 18px;
    font-weight: 600;
    color: #ffff;
    line-height: 35px;
    width: 28vw;
}
.left-3{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffff;
    font-size: 65px;
    margin-top: 25px;
}
.middle{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* width: 20vw; */
    margin-right: 100px;
}
.middle-title{
    font-family: inter;
    font-size: 35px;
    font-weight: 700;
    color: #FFCF24;
    margin-bottom: 12px;
}
.middle-links{
    font-family: poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
    color: #ffff;
    display: flex;
    align-items: center;
    text-decoration: none;
}
.middle-links:active{
    color: #FFCF24;
}
.right{
    display: flex;
    flex-direction: column;
   align-items: flex-start;
   justify-content: flex-start; 
   margin-left: 50px;
}
.right-title{
    font-family: inter;
    font-size: 35px;
    font-weight: 700;
    line-height: 51px;
    color: #FFCF24;
    margin-bottom: 12px;
}
.right-1{
    font-family: poppins;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    color: #ffff;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
}
.address{
    font-family: poppins;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    color: #ffff;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    width: 300px;
}
.right-icon{
    font-size: 27px;
    margin-right: 7px;
}
.hr{
    color: #ffff;
    width: 95vw;
    margin-top: 40px;
}
.bottom{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}
.bottom-icon{
    color: #ffff;
    font-size: 27px;
    margin-right: 10px;
}
.bottom-content{
    font-family: poppins;
    color: #ffff;
    font-size: 17px;
    font-weight: 500;
    margin-top: 20px;
}

@media screen and (max-width: 1200px) {
    .wrapper{
        background-image: url('../../Assets/vector12.png');
        width: 100vw;
        height: 40rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .container{
        width: 90vw;
    }
    .top{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 30px 0px 0px 0px;
    }
    .left{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 40vw;
        margin-right: 100px;
        margin-top: -50px;
    }
    .left-1-con{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 15vw;
        margin-left: -35px;
        margin-bottom: -10px;
    }
    .left-1{
    }
    .left-2{
        font-family: Plus Jakarta Sans;
        font-size: 18px;
        font-weight: 600;
        color: #ffff;
        line-height: 35px;
        width: 28vw;
    }
    .left-3{
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #ffff;
        font-size: 65px;
        margin-top: 25px;
    }
    .middle{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        /* width: 20vw; */
        margin-right: 100px;
    }
    .middle-title{
        font-family: inter;
        font-size: 35px;
        font-weight: 700;
        color: #FFCF24;
        margin-bottom: 12px;
    }
    .middle-links{
        font-family: poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: 45px;
        color: #ffff;
        display: flex;
        align-items: center;
        text-decoration: none;
    }
    .middle-links:active{
        color: #FFCF24;
    }
    .right{
        display: flex;
        flex-direction: column;
       align-items: flex-start;
       justify-content: flex-start; 
       margin-left: 50px;
    }
    .right-title{
        font-family: inter;
        font-size: 35px;
        font-weight: 700;
        line-height: 51px;
        color: #FFCF24;
        margin-bottom: 12px;
    }
    .right-1{
        font-family: poppins;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        color: #ffff;
        font-size: 18px;
        font-weight: 600;
        line-height: 30px;
    }
    .address{
        font-family: poppins;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        color: #ffff;
        font-size: 18px;
        font-weight: 600;
        line-height: 30px;
        width: 300px;
    }
    .right-icon{
        font-size: 27px;
        margin-right: 7px;
    }
    .hr{
        color: #ffff;
        width: 95vw;
        margin-top: 40px;
    }
    .bottom{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
    }
    .bottom-icon{
        color: #ffff;
        font-size: 27px;
        margin-right: 10px;
    }
    .bottom-content{
        font-family: poppins;
        color: #ffff;
        font-size: 17px;
        font-weight: 500;
        margin-top: 20px;
    }

}
@media screen and (max-width: 1024px) {
    .wrapper{
        background-image: url('../../Assets/vector12.png');
        width: 100vw;
        height: 38rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px 20px 0px 20px;
    }
    .container{
        width: 90vw;
    }
    .top{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .left{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 35vw;
        margin-right: 0px;
        margin-top: -70px;
    }
    .left-1-con{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 15vw;
        margin-left: -35px;
        margin-bottom: -15px;
    }
    .left-1{
    }
    .left-2{
        font-family: Plus Jakarta Sans;
        font-size: 16px;
        font-weight: 500;
        color: #ffff;
        line-height: 25px;
        width: 28vw;
    }
    .left-3{
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #ffff;
        font-size: 50px;
        margin-top: 25px;
    }
    .middle{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-right: 100px;
    }
    .middle-title{
        font-family: inter;
        font-size: 30px;
        font-weight: 600;
        color: #FFCF24;
        margin-bottom: 10px;
    }
    .middle-links{
        font-family: poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 35px;
        color: #ffff;
        display: flex;
        align-items: center;
    }
    .right{
        display: flex;
        flex-direction: column;
       align-items: flex-start;
       justify-content: flex-start; 
       margin-left: 0px;
    }
    .right-title{
        font-family: inter;
        font-size: 30px;
        font-weight: 600;
        line-height: 51px;
        color: #FFCF24;
        margin-bottom: 10px;
    }
    .right-1{
        font-family: poppins;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #ffff;
        font-size: 16px;
        font-weight: 500;
        line-height: 40px;
    }
    .address{
        font-family: poppins;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        color: #ffff;
        font-size: 16px;
        font-weight: 500;
        line-height: 30px;
        width: 300px;
    }
    .right-icon{
        font-size: 23px;
        margin-right: 7px;
    }
    .hr{
        color: #ffff;
        width: 95vw;
        margin-top: 20px;
    }
    .bottom{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0px;
    }
    .bottom-icon{
        color: #ffff;
        font-size: 25px;
        margin-right: 10px;
    }
    .bottom-content{
        font-family: poppins;
        color: #ffff;
        font-size: 16px;
        font-weight: 400;
    }

}
@media screen and (max-width: 768px) {
    .wrapper{
        background-image: url('../../Assets/vector12.png');
        width: 100vw;
        height: 29rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0px 20px 0px 20px;
    }
    .container{
        width: 90vw;
    }
    .top{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .left{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 30vw;
        margin-right: 0px;
        margin-top: -20px;
    }
    .left-1-con{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 10vw;
        margin-left: -15px;
        margin-bottom: -20px;
    }
    .left-1{
    }
    .left-2{
        font-family: Plus Jakarta Sans;
        font-size: 13px;
        font-weight: 400;
        color: #ffff;
        line-height: 20px;
        width: 28vw;
    }
    .left-3{
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #ffff;
        font-size: 40px;
        margin-top: 0px;
    }
    .middle{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin: 30px 50px;
    }
    .middle-title{
        font-family: inter;
        font-size: 25px;
        font-weight: 500;
        color: #FFCF24;
        margin-bottom: 5px;
    }
    .middle-links{
        font-family: poppins;
        font-size: 13px;
        font-weight: 400;
        line-height: 25px;
        color: #ffff;
        display: flex;
        align-items: center;
    }
    .right{
        display: flex;
        flex-direction: column;
       align-items: flex-start;
       justify-content: center; 
       margin: 30px 0px 30px 0px;
    }
    .right-title{
        font-family: inter;
        font-size: 25px;
        font-weight: 500;
        line-height: 35px;
        color: #FFCF24;
        margin-bottom: 5px;
    }
    .right-1{
        font-family: poppins;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #ffff;
        font-size: 13px;
        font-weight: 400;
        line-height: 30px;
    }
    .address{
        font-family: poppins;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        color: #ffff;
        font-size: 13px;
        font-weight: 400;
        line-height: 30px;
        width: 300px;
    }
    .right-icon{
        font-size: 20px;
        margin-right: 7px;
        margin-top: 5px;
    }
    .hr{
        color: #ffff;
        width: 95vw;
        margin-top: 10px;
    }
    .bottom{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -20px;
        padding-bottom: 0px;
    }
    .bottom-icon{
        color: #ffff;
        font-size: 20px;
        margin-right: 10px;
    }
    .bottom-content{
        font-family: poppins;
        color: #ffff;
        font-size: 13px;
        font-weight: 300;
    }

}
@media screen and (max-width: 480px) {
    .wrapper{
        background-image: url('../../Assets/vector12.png');
        width: 100vw;
        height: 42.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5px 0px 0px 0px;
    }
    .container{
        width: 100vw;
    }
    .top{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin: 0px 20px 0px 20px;
    }
    .left{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 80vw;
        margin-right: 0px;
        margin-top: 0px;
    }
    .left-1-con{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 25vw;
        margin-left: 0px;
        margin-bottom: -15px;
    }
    .left-1{
        width: 100px;
    }
    .left-2{
        font-family: Plus Jakarta Sans;
        font-size: 11px;
        font-weight: 300;
        color: #ffff;
        line-height: 20px;
        width: 85vw;
        margin-bottom: -10px;
    }
    .left-3{
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #ffff;
        font-size: 35px;
        margin-top: 20px;
    }
    .middle{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin: 20px 0px 0px 0px;
    }
    .middle-title{
        font-family: inter;
        font-size: 18px;
        font-weight: 400;
        color: #FFCF24;
        margin-bottom: 15px;
    }
    .middle-links{
        font-family: poppins;
        font-size: 14px;
        font-weight: 300;
        line-height: 12px;
        margin: 8px 0px;
        color: #ffff;
        display: flex;
        align-items: center;
    }
    .right{
        display: flex;
        flex-direction: column;
       align-items: flex-start;
       justify-content: center; 
       margin-left: 0px;
    }
    .right-title{
        font-family: inter;
        font-size: 18px;
        font-weight: 400;
        line-height: 0px;
        color: #FFCF24;
        margin-bottom: 20px;
    }
    .right-1{
        font-family: poppins;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #ffff;
        font-size: 13px;
        font-weight: 300;
        line-height: 18px;
    }
    .address{
        font-family: poppins;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        color: #ffff;
        font-size: 13px;
        font-weight: 300;
        line-height: 18px;
        width: 300px;
    }
    .right-icon{
        font-size: 20px;
        margin-right: 7px;
    }
    .hr{
        color: #ffff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 95vw;
        margin: -25px 0px 0px 10px;
    }
    .bottom{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0px;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
    .bottom-icon{
        color: #ffff;
        font-size: 18px;
        margin: 15px 10px 18px 0px;
    }
    .bottom-content{
        font-family: poppins;
        color: #ffff;
        font-size: 11px;
        font-weight: 200;
        text-align: center;
        margin: 18px 0px 18px 0px;
    }

}