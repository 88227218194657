.wrapper{
    background-image: url(../../Assets/img1.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 45rem;
    padding: 0px 100px;
}
.title-con{
    width: 60%;
}
.title{
    font-family: 'Merriweather';
    font-size: 60px;
    font-weight: 700;
    color: #FFCF24;
    line-height: 80px;
    margin-top: 60px;
}
.title-words{
    font-family: Plus Jakarta Sans;
    color: #ffff;
    background: none;
    font-size: 18px;
    font-weight: 600;
    width: 46vw;
    margin: 30px 0px 35px 0px;
    line-height: 35px;
}
.btn-con{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.btn-left{
    background-color: #FFCF24;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    padding: 18px 38px;
    border: none;
}
.btn-left:hover{
    background-color: #15ABAB;
}
.btn-right{
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    padding: 17px 38px;
    margin: 0px 40px;
    color: #ffff;
    border: 2px solid #ffff;
    background-color: transparent;
}
.btn-right:hover{
    border: 2px solid #FFCF24;
}

@media (max-width: 1024px) {
    .wrapper{
        background-image: url(../../Assets/img1.png);
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 38rem;
        padding: 0px 90px;
    }
    .title{
        font-family: 'Merriweather';
        font-size: 40px;
        font-weight: 700;
        color: #FFCF24;
        width: 100%;
        line-height: 55px;
        margin-top: 40px;
    }
    .title-words{
        color: #ffff;
        font-size: 16px;
        font-weight: 400;
        width: 46vw;
        margin: 15px 0px 30px 0px;
        line-height: 28px;
    }
    .btn-con{
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .btn-left{
        background-color: #FFCF24;
        font-family: Inter;
        font-size: 12px;
        font-weight: 600;
        padding: 16px 35px;
    }
    .btn-right{
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        padding: 15px 35px;
        margin: 0px 40px;
        color: #ffff;
        border: 2px solid #ffff;
    }

}
@media screen and (max-width: 768px) {
    .wrapper{
        background-image: url(../../Assets/img1.png);
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 32rem;
        padding: 0px 50px;
    }
    .title{
        font-family: 'Merriweather';
        font-size: 35px;
        font-weight: 600;
        color: #FFCF24;
        line-height: 45px;
        padding-top: 60px;
    }
    .title-words{
        color: #ffff;
        font-size: 14px;
        font-weight: 200;
        width: 50vw;
        margin: 10px 0px 20px 0px;
        line-height: 25px;
    }
    .btn-con{
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .btn-left{
        background-color: #FFCF24;
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        padding: 14px 35px;
    }
    .btn-right{
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        padding: 12px 35px;
        margin: 0px 40px;
        color: #ffff;
        border: 1px solid #ffff;
    }

}

@media screen and (max-width: 480px) {
    .wrapper{
        background-image: url(../../Assets/img1.png);
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 30rem;
        padding: 0px 30px;
    }
    .title{
        font-family: 'Merriweather';
        font-size: 25px;
        font-weight: 500;
        color: #FFCF24;
        width: 100vw;
        line-height: 40px;
        padding-top: 20px;
    }
    .title-words{
        color: #ffff;
        font-size: 13px;
        font-weight: 400;
        width: 80vw;
        margin: 5px 0px 20px 0px;
        line-height: 23px;
    }
    .btn-con{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100vw;
    }
    .btn-left{
        background-color: #FFCF24;
        font-family: Inter;
        font-size: 10px;
        font-weight: 500;
        padding: 10px 25px;
    }
    .btn-right{
        font-family: Inter;
        font-size: 10px;
        font-weight: 400;
        padding: 9px 25px;
        margin: 0px 25px;
        color: #ffff;
        border: 1px solid #ffff;
    }

}