.wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
}
.heading{
    font-family: merriweather;
    font-size: 40px;
    font-weight: 700;
    margin-top: 75px;
    margin-bottom: 30px;
    color: #616161;
}
.div-1{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    margin: 75px 0px;
}
.left-1{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50vw;
}
.image-1{
    width: 375px;
    height: 450px;
}
.right-1{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 50vw;
}
.right-title-1{
    font-family: Merriweather;
    font-size: 30px;
    font-weight: 800;
    color: #F01B1B;
    margin-bottom: 22px;
}
.div-words-1{
    font-family: Plus Jakarta Sans;
    font-size: 21px;
    font-weight: 500;
    color: #939090;
    line-height: 48px;
    width: 40vw;
}

.div-2{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    margin: 75px 100px;
}
.left-2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 40vw;
    margin-left: 160px;
}
.left-title-2{
    font-family: Merriweather;
    font-size: 30px;
    font-weight: 800;
    color: #F01B1B;
    margin-bottom: 22px; 
}
.div-words-2{
    font-family: Plus Jakarta Sans;
    font-size: 21px;
    font-weight: 500;
    color: #939090;
    line-height: 48px;
    width: 40vw;
}
.right-2{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50vw;
}
.image-2{
    width: 375px;
    height: 450px;
}
.bottom-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    background-color: #FAFAFA;
    margin-top: 50px;
    padding-top: 75px;
    padding-bottom: 60px;
}
.btm-words{
    font-family: Merriweather;
    font-size: 55px;
    font-weight: 700;
    text-align: center;
    line-height: 80px;
    width: 70vw;
    color: #15ABAB;
}
.btm-btn{
    font-family: inter;
    background-color: #FFCF24;
    border: none;
    outline: none;
    font-size: 20px;
    font-weight: 600;
    color: #464545;
    margin-top: 40px;
    width: 325px;
    height: 75px;
}
.btm-btn:hover{
    background-color: #15ABAB;
}

@media screen and (max-width: 1024px) {
    .wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
    }
    .heading{
        font-family: merriweather;
        font-size: 28px;
        font-weight: 700;
        margin-top: 75px;
        margin-bottom: 5px;
        color: #616161;
    }
    .div-1{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100vw;
        margin: 60px 0px;
    }
    .left-1{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50vw;
    }
    .image-1{
        width: 375px;
        height: 400px;
    }
    .right-1{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 50vw;
    }
    .right-title-1{
        font-family: Merriweather;
        font-size: 23px;
        font-weight: 800;
        color: #F01B1B;
        margin-bottom: 12px;
    }
    .div-words-1{
        font-family: Plus Jakarta Sans;
        font-size: 16px;
        font-weight: 500;
        color: #939090;
        line-height: 40px;
        width: 40vw;
    }
    
    .div-2{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100vw;
        margin: 60px 160px 60px 0px;
    }
    .left-2{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 40vw;
        margin-left: 160px;
    }
    .left-title-2{
        font-family: Merriweather;
        font-size: 23px;
        font-weight: 800;
        color: #F01B1B;
        margin-bottom: 12px; 
    }
    .div-words-2{
        font-family: Plus Jakarta Sans;
        font-size: 16px;
        font-weight: 500;
        color: #939090;
        line-height: 40px;
        width: 40vw;
    }
    .right-2{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50vw;
    }
    .image-2{
        width: 375px;
        height: 400px;
    }

    .bottom-div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
        background-color: #FAFAFA;
        margin-top: 20px;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .btm-words{
        font-family: Merriweather;
        font-size: 40px;
        font-weight: 700;
        text-align: center;
        line-height: 60px;
        width: 85vw;
        color: #15ABAB;
    }
    .btm-btn{
        font-family: inter;
        background-color: #FFCF24;
        border: none;
        outline: none;
        font-size: 17px;
        font-weight: 600;
        color: #464545;
        margin-top: 20px;
        width: 250px;
        height: 60px;
    }

}
@media screen and (max-width: 768px) {
    .wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
    }
    .heading{
        font-family: merriweather;
        font-size: 28px;
        font-weight: 700;
        margin-top: 75px;
        margin-bottom: 5px;
        color: #616161;
    }
    .div-1{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100vw;
        margin: 50px 0px;
    }
    .left-1{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50vw;
    }
    .image-1{
        width: 330px;
        height: 390px;
    }
    .right-1{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 50vw;
    }
    .right-title-1{
        font-family: Merriweather;
        font-size: 20px;
        font-weight: 800;
        color: #F01B1B;
        margin-bottom: 5px;
    }
    .div-words-1{
        font-family: Plus Jakarta Sans;
        font-size: 15px;
        font-weight: 500;
        color: #939090;
        line-height: 30px;
        width: 40vw;
    }
    
    .div-2{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100vw;
        margin: 50px 0px 50px 0px;
    }
    .left-2{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 40vw;
        margin-left: 40px;
    }
    .left-title-2{
        font-family: Merriweather;
        font-size: 20px;
        font-weight: 800;
        color: #F01B1B;
        margin-bottom: 12px; 
    }
    .div-words-2{
        font-family: Plus Jakarta Sans;
        font-size: 15px;
        font-weight: 500;
        color: #939090;
        line-height: 30px;
        width: 40vw;
    }
    .right-2{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50vw;
        margin-right: 50px;
    }
    .image-2{
        width: 330px;
        height: 390px;
    }

    .bottom-div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
        background-color: #FAFAFA;
        margin-top: 0px;
        padding-top: 20px;
        padding-bottom: 30px;
    }
    .btm-words{
        font-family: Merriweather;
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        line-height: 45px;
        width: 90vw;
        color: #15ABAB;
    }
    .btm-btn{
        font-family: inter;
        background-color: #FFCF24;
        border: none;
        outline: none;
        font-size: 13px;
        font-weight: 600;
        color: #464545;
        margin-top: 10px;
        width: 170px;
        height: 50px;
    }

}

@media screen and (max-width: 640px) {
    .wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
    }
    .heading{
        font-family: merriweather;
        font-size: 23px;
        font-weight: 700;
        margin-top: 50px;
        margin-bottom: 0px;
        color: #616161;
    }
    .div-1{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        width: 100vw;
        margin: 40px 0px;
    }
    .left-1{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85vw;
    }
    .image-1{
        width: 350px;
        height: 350px;
    }
    .right-1{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 85vw;
    }
    .right-title-1{
        font-family: Merriweather;
        font-size: 18px;
        font-weight: 800;
        color: #F01B1B;
        margin-bottom: 5px;
    }
    .div-words-1{
        font-family: Plus Jakarta Sans;
        font-size: 13px;
        font-weight: 400;
        color: #939090;
        line-height: 30px;
        width: 85vw;
    }
    
    .div-2{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100vw;
        margin: 40px 0px;
    }
    .left-2{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 85vw;
        margin-left: 0px;
    }
    .left-title-2{
        font-family: Merriweather;
        font-size: 20px;
        font-weight: 800;
        color: #F01B1B;
        margin-bottom: 5px; 
    }
    .div-words-2{
        font-family: Plus Jakarta Sans;
        font-size: 13px;
        font-weight: 400;
        color: #939090;
        line-height: 30px;
        width: 85vw;
    }
    .right-2{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85vw;
        margin-right: 0px;
    }
    .image-2{
        width: 350px;
        height: 350px;
    }

    .bottom-div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
        background-color: #FAFAFA;
        margin-top: 0px;
        padding-top: 20px;
        padding-bottom: 30px;
    }
    .btm-words{
        font-family: Merriweather;
        font-size: 21px;
        font-weight: 700;
        text-align: center;
        line-height: 40px;
        width: 90vw;
        color: #15ABAB;
    }
    .btm-btn{
        font-family: inter;
        background-color: #FFCF24;
        border: none;
        outline: none;
        font-size: 12px;
        font-weight: 600;
        color: #464545;
        margin-top: 10px;
        width: 140px;
        height: 40px;
    }

}