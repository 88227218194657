.wrapper{
    margin-top: 60px;
    margin-bottom: 50px;
}
.top{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.top-title{
    font-family: Merriweather;
    color: #616161;
    font-size: 50px;
    font-weight: 700;
    text-align: center;
    width: 45%;
    line-height: 60px;
}
.top-card-con{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 48px;
}
.top-card1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid #A6A6A63D;
    padding: 30px 35px;
    margin: 0px 55px;
    margin-bottom: 50px;
}
.top-img-div1{
    background-color: #15ABAB;
    border-radius: 50%;
}
.top-img-div2{
    background-color: #F01B1B;
    border-radius: 50%;
}
.top-img-div3{
    background-color: #FFCF24;
    border-radius: 50%;
}
.top-img1{
    padding: 40px;
}
.top-card-title{
    font-family: Plus Jakarta Sans;
    font-size: 32px;
    font-weight: 500;
    color: #616161;
    margin-top: 12px;
    margin-bottom: 2px;
}
.top-card-words{
    font-family: Plus Jakarta Sans;
    font-size: 13px;
    font-weight: 500;
    color: #939090;
    margin-bottom: 16px;
}
.top-card-btn1{
    font-family: inter;
    color: #ffff;
    background-color: #15ABAB;
    padding: 20px 0px;
    width: 14rem;
    font-size: 16px;
    font-weight: 600;
    border: none;
}
.top-card-btn1:hover{
    background-color: #FFCF24;
}
.top-card-btn2{
    font-family: inter;
    color: #ffff;
    background-color: #F01B1B;
    padding: 20px 0px;
    width: 14rem;
    font-size: 16px;
    font-weight: 600;
    border: none;
}
.top-card-btn2:hover{
    border: 2px solid #F01B1B;
    background-color: #ffff;
    color: black;
}
.top-card-btn3{
    font-family: inter;
    color: black;
    background-color: #FFCF24;
    padding: 20px 0px;
    width: 14rem;
    font-size: 16px;
    font-weight: 600;
    border: none;
}
.top-card-btn3:hover{
    background-color: #15ABAB;
}
.bottom{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 45px;
}
.bottom-left{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40vw;
    margin: 0px 50px;
}
.bottom-img{
    height: 41rem;
    width: 40vw;
}
.bottom-right{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 40vw;
    margin: 30px 50px;
}
.bottom-about{
    font-family: Merriweather;
    color: #FFCF24;
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 10px;
}
.bottom-title{
    font-family: Merriweather;
    color: #616161;
    line-height: 57px;
    font-size: 50px;
    font-weight: 500;
    width: 35vw;
}
.bottom-words{
    font-family: Plus Jakarta Sans;
    color: #939090;
    line-height: 35px;
    margin-top: 10px;
    font-weight: 500;
    width: 38vw;
}
.bottom-btn{
    font-family: inter;
    color: black;
    background-color: #FFCF24;
    margin-top: 15px;
    font-size: 18px;
    font-weight: 500;
    padding: 18px 50px;
    border: none;
}
.bottom-btn:hover{
    background-color: #15ABAB;
}

@media screen and (max-width: 1024px) {
    .wrapper{
        margin-top: 60px;
        margin-bottom: 50px;
    }
    .top{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .top-title{
        font-family: Merriweather;
        color: #616161;
        font-size: 45px;
        font-weight: 600;
        text-align: center;
        width: 50%;
        line-height: 50px;
    }
    .top-card-con{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 48px;
    }
    .top-card1{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 2px solid #A6A6A63D;
        padding: 20px 25px;
        margin: 0px 40px;
        margin-bottom: 50px;
    }
    .top-img-div1{
        background-color: #15ABAB;
        border-radius: 50%;
    }
    .top-img1{
        width: 80px;
    }
    .top-img-div2{
        background-color: #F01B1B;
        border-radius: 50%;
    }
    .top-img-div3{
        background-color: #FFCF24;
        border-radius: 50%;
    }
    .top-img1{
        padding: 20px;
    }
    .top-card-title{
        font-size: 20px;
        font-weight: 500;
        color: #616161;
        margin-top: 12px;
        margin-bottom: 2px;
    }
    .top-card-words{
        font-size: 10px;
        font-weight: 500;
        color: #939090;
        margin-bottom: 16px;
    }
    .top-card-btn1{
        color: #ffff;
        background-color: #15ABAB;
        padding: 15px 0px;
        width: 12rem;
        font-size: 13px;
        font-weight: 600;
        text-align: center;
    }
    .top-card-btn2{
        color: #ffff;
        background-color: #F01B1B;
        padding: 15px 0px;
        width: 12rem;
        font-size: 13px;
        font-weight: 600;
        text-align: center;
    }
    .top-card-btn3{
        color: black;
        background-color: #FFCF24;
        padding: 15px 0px;
        width: 12rem;
        font-size: 13px;
        font-weight: 600;
        text-align: center;
    }
    .bottom{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-top: 45px;
    }
    .bottom-left{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50vw;
        margin: 0px 20px;
    }
    .bottom-img{
        height: 33rem;
        width: 40vw;
    }
    .bottom-right{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 50vw;
        margin: 10px 20px;
    }
    .bottom-about{
        font-family: Merriweather;
        color: #FFCF24;
        font-size: 26px;
        font-weight: 500;
        margin-bottom: 10px;
    }
    .bottom-title{
        font-family: Merriweather;
        color: #616161;
        line-height: 45px;
        font-size: 35px;
        font-weight: 600;
        width: 40vw;
    }
    .bottom-words{
        color: #939090;
        line-height: 32px;
        margin-top: 0px;
        font-weight: 500;
        width: 40vw;
    }
    .bottom-btn{
        color: black;
        background-color: #FFCF24;
        margin-top: 15px;
        font-size: 14px;
        font-weight: 500;
        padding: 12px 35px;
    }

}
@media screen and (max-width: 768px) {
    .wrapper{
        margin-top: 60px;
        margin-bottom: 50px;
    }
    .top{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .top-title{
        font-family: Merriweather;
        color: #616161;
        font-size: 35px;
        font-weight: 600;
        text-align: center;
        width: 50%;
        line-height: 40px;
    }
    .top-card-con{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 48px;
    }
    .top-card1{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 2px solid #A6A6A63D;
        padding: 20px 15px;
        margin: 0px 10px;
        margin-bottom: 50px;
    }
    .top-img-div1{
        background-color: #15ABAB;
        border-radius: 50%;
    }
    .top-img1{
        width: 80px;
    }
    .top-img-div2{
        background-color: #F01B1B;
        border-radius: 50%;
    }
    .top-img-div3{
        background-color: #FFCF24;
        border-radius: 50%;
    }
    .top-img1{
        padding: 20px;
    }
    .top-card-title{
        font-size: 16px;
        font-weight: 500;
        color: #616161;
        margin-top: 12px;
        margin-bottom: 2px;
    }
    .top-card-words{
        font-size: 10px;
        font-weight: 500;
        color: #939090;
        margin-bottom: 16px;
    }
    .top-card-btn1{
        color: #ffff;
        background-color: #15ABAB;
        padding: 15px 0px;
        width: 10rem;
        font-size: 13px;
        font-weight: 600;
        text-align: center;
    }
    .top-card-btn2{
        color: #ffff;
        background-color: #F01B1B;
        padding: 15px 0px;
        width: 10rem;
        font-size: 13px;
        font-weight: 600;
        text-align: center;
    }
    .top-card-btn3{
        color: black;
        background-color: #FFCF24;
        padding: 15px 0px;
        width: 10rem;
        font-size: 13px;
        font-weight: 600;
        text-align: center;
    }
    .bottom{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-top: 45px;
    }
    .bottom-left{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50vw;
        margin: 0px 10px;
    }
    .bottom-img{
        height: 31rem;
        width: 40vw;
    }
    .bottom-right{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 50vw;
        margin: 10px 10px;
    }
    .bottom-about{
        font-family: Merriweather;
        color: #FFCF24;
        font-size: 25px;
        font-weight: 500;
        margin-bottom: 5px;
    }
    .bottom-title{
        font-family: Merriweather;
        color: #616161;
        line-height: 37px;
        font-size: 27px;
        font-weight: 600;
        width: 45vw;
    }
    .bottom-words{
        color: #939090;
        line-height: 32px;
        margin-top: 0px;
        font-size: 14px;
        font-weight: 500;
        width: 45vw;
    }
    .bottom-btn{
        color: black;
        background-color: #FFCF24;
        margin-top: 10px;
        font-size: 12px;
        font-weight: 500;
        padding: 10px 25px;
    }

}
@media screen and (max-width: 540px) {
    .wrapper{
        margin-top: 60px;
        margin-bottom: 50px;
    }
    .top{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .top-title{
        font-family: Merriweather;
        color: #616161;
        font-size: 25px;
        font-weight: 600;
        text-align: center;
        width: 90%;
        line-height: 30px;
    }
    .top-card-con{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }
    .top-card1{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 2px solid #A6A6A63D;
        padding: 20px 25px;
        margin: 0px 0px;
        margin-bottom: 30px;
    }
    .top-img-div1{
        background-color: #15ABAB;
        border-radius: 50%;
    }
    .top-img1{
        width: 80px;
    }
    .top-img-div2{
        background-color: #F01B1B;
        border-radius: 50%;
    }
    .top-img-div3{
        background-color: #FFCF24;
        border-radius: 50%;
    }
    .top-img1{
        padding: 20px;
    }
    .top-card-title{
        font-size: 18px;
        font-weight: 500;
        color: #616161;
        margin-top: 12px;
        margin-bottom: 2px;
    }
    .top-card-words{
        font-size: 10px;
        font-weight: 500;
        color: #939090;
        margin-bottom: 7px;
    }
    .top-card-btn1{
        color: #ffff;
        background-color: #15ABAB;
        padding: 10px 40px;
        font-size: 11px;
        font-weight: 500;
        text-align: center;
    }
    .top-card-btn2{
        color: #ffff;
        background-color: #F01B1B;
        padding: 10px 55px;
        font-size: 11px;
        font-weight: 500;
        text-align: center;
    }
    .top-card-btn3{
        color: black;
        background-color: #FFCF24;
        padding: 10px 40px;
        font-size: 11px;
        font-weight: 500;
        text-align: center;
    }
    .bottom{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }
    .bottom-left{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85vw;
        margin: 0px 0px;
    }
    .bottom-img{
        height: 23rem;
        width: 80vw;
    }
    .bottom-right{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
        margin: 0px 0px;
    }
    .bottom-about{
        font-family: Merriweather;
        color: #FFCF24;
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 5px;
    }
    .bottom-title{
        font-family: Merriweather;
        color: #616161;
        line-height: 30px;
        font-size: 23px;
        font-weight: 600;
        width: 85vw;
        text-align: center;
    }
    .bottom-words{
        color: #939090;
        line-height: 25px;
        margin-top: 10px;
        font-size: 12px;
        font-weight: 500;
        width: 85vw;
        text-align: center;
    }
    .bottom-btn{
        color: black;
        background-color: #FFCF24;
        margin-top: 15px;
        margin-bottom: 35px;
        font-size: 10px;
        font-weight: 500;
        padding: 10px 25px;
    }

}