.wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
}
.title-con{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.title{
    font-family: Merriweather;
    color: #FFCF24;
    text-align: center;
    font-size: 42px;
    font-weight: 600;
}
.title-hr{
    width: 40vw;
    color: #807c7c;
    margin-top: -10px;
}
.form-con{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    margin-bottom: 80px;
}
.input-con{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 25px;
}
.label-1{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}
.input-1{
    height: 55px;
    width: 35vw;
    border-radius: 5px;
    border: 1px solid rgb(160, 158, 158);
}
.textarea{
    width: 35vw;
    border-radius: 5px;
    border: 1px solid rgb(160, 158, 158);
}
.form-btn{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 34vw;
    margin-top: 10px;
}
.btn{
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    background-color: #239407;
    color: #ffff;
    border: none;
    outline: none;
    text-align: center;
    height: 60px;
    width: 210px;
}

@media screen and (max-width: 1200px) {
    .wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 100px;
    }
    .title-con{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .title{
        font-family: Merriweather;
        color: #FFCF24;
        text-align: center;
        font-size: 42px;
        font-weight: 600;
    }
    .title-hr{
        width: 50vw;
        color: #807c7c;
        margin-top: -10px;
    }
    .form-con{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .form{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 50px;
        margin-bottom: 80px;
    }
    .input-con{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 25px;
    }
    .label-1{
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
    }
    .input-1{
        height: 55px;
        width: 35vw;
        border-radius: 5px;
        border: 1px solid rgb(160, 158, 158);
    }
    .textarea{
        width: 35vw;
        border-radius: 5px;
        border: 1px solid rgb(160, 158, 158);
    }
    .form-btn{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 34vw;
        margin-top: 10px;
    }
    .btn{
        font-family: Inter;
        font-size: 16px;
        font-weight: 600;
        background-color: #239407;
        color: #ffff;
        border: none;
        outline: none;
        text-align: center;
        height: 60px;
        width: 210px;
    }

}
@media screen and (max-width: 1024px) {
    .wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 100px;
    }
    .title-con{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .title{
        font-family: Merriweather;
        color: #FFCF24;
        text-align: center;
        font-size: 30px;
        font-weight: 600;
    }
    .title-hr{
        width: 38vw;
        color: #807c7c;
        margin-top: -10px;
    }
    .form-con{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .form{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 50px;
        margin-bottom: 80px;
    }
    .input-con{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 25px;
    }
    .label-1{
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
    }
    .input-1{
        height: 55px;
        width: 35vw;
        border-radius: 5px;
        border: 1px solid rgb(160, 158, 158);
    }
    .textarea{
        width: 35vw;
        border-radius: 5px;
        border: 1px solid rgb(160, 158, 158);
    }
    .form-btn{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 34vw;
        margin-top: 5px;
    }
    .btn{
        font-family: Inter;
        font-size: 13px;
        font-weight: 500;
        background-color: #239407;
        color: #ffff;
        border: none;
        outline: none;
        text-align: center;
        height: 50px;
        width: 150px;
    }
}
@media screen and (max-width: 768px) {
    .wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 70px;
    }
    .title-con{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .title{
        font-family: Merriweather;
        color: #FFCF24;
        text-align: center;
        font-size: 27px;
        font-weight: 600;
    }
    .title-hr{
        width: 45vw;
        color: #807c7c;
        margin-top: -10px;
    }
    .form-con{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .form{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 40px;
        margin-bottom: 80px;
    }
    .input-con{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 20px;
    }
    .label-1{
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
    }
    .input-1{
        height: 50px;
        width: 55vw;
        border-radius: 5px;
        border: 1px solid rgb(160, 158, 158);
    }
    .textarea{
        width: 55vw;
        border-radius: 5px;
        border: 1px solid rgb(160, 158, 158);
    }
    .form-btn{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 55vw;
        margin-top: 5px;
    }
    .btn{
        font-family: Inter;
        font-size: 13px;
        font-weight: 500;
        background-color: #239407;
        color: #ffff;
        border: none;
        outline: none;
        text-align: center;
        height: 50px;
        width: 150px;
    }

}
@media screen and (max-width: 480px) {
    .wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 70px;
    }
    .title-con{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .title{
        font-family: Merriweather;
        color: #FFCF24;
        text-align: center;
        font-size: 23px;
        font-weight: 600;
    }
    .title-hr{
        width: 75vw;
        color: #807c7c;
        margin-top: -10px;
    }
    .form-con{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .form{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 30px;
        margin-bottom: 60px;
    }
    .input-con{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 20px;
    }
    .label-1{
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 5px;
    }
    .input-1{
        height: 45px;
        width: 80vw;
        border-radius: 5px;
        border: 1px solid rgb(160, 158, 158);
    }
    .textarea{
        width: 80vw;
        border-radius: 5px;
        border: 1px solid rgb(160, 158, 158);
    }
    .form-btn{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 80vw;
        margin-top: 5px;
    }
    .btn{
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        background-color: #239407;
        color: #ffff;
        border: none;
        outline: none;
        text-align: center;
        height: 45px;
        width: 130px;
    }

}
